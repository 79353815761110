.obis-site-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $medium-dark;
  z-index: 0;

  &.color-blue {
    background-color: $themecolor-dark;
  }

  &.color-light {
    background-color: $light;
  }

  &.color-dark {
    background-color: $medium-dark;

    &.darker {
      background-color: $black;
    }
  }

  &.color-white {
    background-color: $white;
  }

  &:not(.clickable) {
    pointer-events: none;
  }

  &.colorfade {

    &.color-blue,
    &.color-light,
    &.color-dark,
    &.color-white {
      transition: background-color 0.3s ease-in-out;
    }
  }
}

*:hover>.obis-site-overlay.colorfade {
  &.color-blue {
    background-color: $themecolor-dark;
  }

  &.color-light {
    background-color: $light;
  }

  &.color-dark {
    background-color: $medium-dark;

    &.darker {
      background-color: $black;
    }
  }

  &.color-white {
    background-color: $white;
  }
}