.obis-site-footer-menu {
  text-align: center;
  font-size: 11pt;
  @media (min-width: $screen-sm-min) {
    text-align: right;
    font-size: 12pt;
  }
  
  font-weight: 600;

  .footer-menu-item {
    display: inline;
    margin: 0 5px;

    .footer-menu-link {
      color: $text-color;
      padding: 5px;

      .footer-menu-label {}
    }

    &.not-last-item {
      &::after {
        content: '|';
        margin-left: 5px;
        margin-right: -5px;
      }
    }
  }
}