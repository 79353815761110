nav.obis-site-mobilemenu {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  @include flexbox-container;
  justify-content: flex-start;

  background-color: rgba(255, 255, 255, 0.7);
  z-index: 50;

  @media (min-width: $screen-xs-min) {
    padding-left: 10%;
  }

  ul.nav.navbar-nav {
    color: $medium-dark;
    font-size: 15pt;
    font-weight: 600;
    margin: 0;

    @media (min-width: $screen-xs-min) {
      font-size: 18pt;
    }

    li.menu-item {
      margin: 0;
      font-weight: bold;

      @media (min-width: $screen-xs-min) {
        margin: 10px 0px;
        line-height: 25px;
      }

      a.menu-link {
        cursor: pointer;
        display: inline-block;
        background-color: transparent;
        padding: 3px 7px;
        text-transform: uppercase;

        color: $medium-dark;
        transition: color 0.3s ease;

        &:hover {
          color: $themecolor;
        }
      }

      ul.submenu {
        list-style: none;
        padding: 0;
        overflow: hidden;
        margin-top: 5px;

        li.menu-item {
          font-weight: normal;
          margin: 0;
        }
      }

      .expander {
        cursor: pointer;
        display: inline-block;
        font-size: 130%;
        line-height: 25px;
        width: 18px;
        text-align: center;
        vertical-align: top;
      }
    }
  }
}