.obis-site-slider {
  position: relative;
  height: 100%;
}

.obis-site-slider,
.slick-slider {
  .slider-contents {
    margin-bottom: 0;
    max-height: 100%;

    .slick-list {
      width: 100%;
      max-height: 100%;

      .slick-track {
        .slick-slide {
          @include slide-height-mixin();

          .obis-site-slider-slide {
            position: relative;

            @include slide-height-mixin();
            @include dynamic-background-preconfig();

            .slide-content-wrapper {
              z-index: 10;
              display: block;
              position: relative;
              width: 100%;
              height: 100%;

              .slide-inner {
                width: 100%;
                height: 100%;

                @include flexbox-container();

                &>.neos-contentcollection {
                  min-width: 100px;

                  @include general-headline-styling;
                }
              }
            }
          }
        }
      }
    }
  }

  .slider-tinted::before {
    z-index: 1;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: "";
    background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.9) 100%);
    top: auto;
    height: 35%;
    pointer-events: none;
  }


  .slider-controls {
    .slider-button {
      position: absolute;
      top: calc(50% - 21px);
      color: $light;

      background: none;
      outline: none !important;

      margin: 0;
      padding: 0;
      font-size: 40px;
      line-height: 40px;

      border: 2px solid $light;
      border-radius: 50%;
      padding: 0px;
      width: 55px;
      height: 55px;
      text-align: center;

      &.button-prev {
        left: 20px;
        padding-right: 5px;
        // text-shadow: 2px 1px 5px $black;
      }

      &.button-next {
        right: 20px;
        padding-left: 5px;
        // text-shadow: -2px 1px 5px $black;
      }
    }

    .slider-pagination {
      bottom: 15px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      text-align: center;

      &>li {
        display: inline-block;
        margin: 0 2px;

        &>button {
          background-color: $white;
          border-radius: 50%;
          border: none;
          height: 12px;
          width: 12px;
          padding: 0;
          font-size: 0;
          outline: none !important;
          transition: background-color 0.2s ease;
          cursor: pointer;
        }

        &.slick-active {
          &>button {
            background-color: $themecolor;
          }
        }
      }
    }
  }
}
