hr.obis-site-divider {
  margin-top: 20px;
  margin-bottom: 20px;

  &.color-blue {
    border-color: $themecolor;
  }

  &.color-dark {
    border-color: $medium-dark;
  }

  &.color-light {
    border-color: $light;
  }

  &.color-white {
    border-color: $white;
  }

  &.big-gap {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.neos-backend {
  hr.obis-site-divider {
    min-height: 5px;

    &.color-blue {
      background: $themecolor;
    }

    &.color-dark {
      background: $medium-dark;
    }

    &.color-light {
      background: $light;
    }

    &.color-white {
      background: $white;
    }
  }
}