div.obis-site-cardgallery {
  margin: 15px 0;

  .gallery-inner {
    @include flexbox-stretch-container;

    justify-content: space-evenly;

    .widthfactor-0 {
      flex-grow: 0;
    }

    .widthfactor-1 {
      flex-grow: 0;
      @media (min-width: $screen-sm-min) {
        flex-basis: 75%;
        margin-left: 12.5%;
      }
    }

    .widthfactor-2 {
      flex-grow: 1;
    }


    @media (min-width: $screen-sm-min) {
      justify-content: space-between;
    }

    .card-item {
      min-height: 200px;
      min-width: 200px;
      max-height: 350px;
      max-width: 500px;

      width: 99%;
      margin-bottom: 1%;

      @media (min-width: $screen-sm-min) {
        width: 49%;
        max-width: unset;
      }

      @media (min-width: $screen-md-min) {
        width: 32%;
      }

      &:before {
        content: "";
        float: left;
        padding-top: 100%;
      }

      &.color-dark {
        background-color: $medium-dark;
        color: $light;
      }

      &.color-light {
        background-color: $light;
        color: $medium-dark;
      }

      &.color-blue {
        // background-color: $themecolor;
        color: $light;

        @include hsl-blue-background-gradient-diagonal;
      }

      &.color-white {
        background-color: $white;
        color: $medium-dark;
      }

      &.obis-site-cardgallery-card {
        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;

          @include dynamic-background-preconfig;

          .content-wrapper {
            @include flexbox-container;

            position: relative;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 5px;

            &>.neos-contentcollection {
              @include general-headline-styling;

              min-width: 100px;

              h1 {
                font-size: 3.5em;
                margin: 5px 0;
              }

              h2 {
                font-size: 3em;
                margin: 5px 0;
              }

              h3 {
                font-size: 2.5em;
                margin: 5px 0;
              }

              h4 {
                font-size: 2em;
                margin: 5px 0;
              }

              h5 {
                font-size: 1.5em;
                margin: 5px 0;
              }
            }

            .color-blue,
            .color-dark,
            .color-light {
              transition: all 0.3s ease-in-out;
            }

            &:hover {

              .color-blue,
              .color-dark {
                color: $light;
                text-shadow: 0 0 3px $medium-dark;
              }

              .color-light {
                // color: $themecolor;
                color: $medium-dark;
                text-shadow: 0 0 3px $light;
              }
            }
          }
        }
      }

      &.obis-site-cardgallery-flipcard {
        max-height: 300px;

        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;

          .frontend,
          .backend {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @include flexbox-container;

            backface-visibility: hidden;
            transition: transform 0.4s ease-in-out;
            padding: 15px;
          }

          .frontend {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            transform: rotateY(0deg);

            .obis-site-headline {
              &>span {
                display: block;
                font-size: 2em;
                font-weight: bold;
                margin: 10px 0;
                text-transform: uppercase;
              }
            }
          }

          .backend {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            transform: rotateY(180deg);

            .info-text {
              text-align: center;
              strong {
                text-decoration: underline;
              }
            }
          }

          &:hover {
            .frontend {
              transform: rotateY(180deg);
            }

            .backend {
              transform: rotateY(0deg);
            }
          }
        }
      }

      &.obis-site-cardgallery-productcard {
        height: 400px;
        max-width: 270px;

        box-shadow: 0 0 0 0 $themecolor;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 0 0 5px $themecolor;
        }

        @media (min-width: $screen-xs-min) {
          max-width: 230px;
        }

        @media (min-width: $screen-md-min) {
          width: 24%;
        }

        @media (min-width: $screen-lg-min) {
          max-width: 230px;
        }

        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: $themecolor;

          .card-appendix {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $themecolor;
            color: white;
            font-size: 15pt;
            min-height: 50px;
            padding: 10px 3px;
            text-align: center;
          }
        }
      }

      &.obis-site-cardgallery-teamcard {
        height: 400px;
        max-width: 270px;

        box-shadow: 0 0 0 0 $themecolor;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 0 0 5px $themecolor;
        }

        @media (min-width: $screen-xs-min) {
          max-width: 230px;
        }

        @media (min-width: $screen-md-min) {
          width: 24%;
        }

        @media (min-width: $screen-lg-min) {
          max-width: 230px;
        }

        .card-inner {
          position: relative;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-color: $themecolor;

          .card-appendix {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $themecolor;
            color: white;
            font-size: 15pt;
            height: 25px;
            overflow-y: hidden;
            padding: 3px 3px 10px 5px;
            text-align: left;
            z-index: 10;
            transition: height 1000ms;

            &::before {
              font-family: 'Glyphicons Halflings';
              content: "\e113";
            }

            &:hover {
              height: 165px;
            }

            &>div {
              min-height: 25px;
            }

            div.team-name {
              font-weight: 700;
            }

            div.team-job {
              font-size: 12pt;
            }

            div.team-phone {
              font-size: 12pt;
              a:hover {
                font-weight: 700;
              }
            }

            div.team-email {
              font-size: 12pt;
              a:hover {
                font-weight: 700;
              }
            }

          }
        }
      }


      &.align-top {
        .card-inner .content-wrapper {
          justify-content: flex-start;
        }
      }

      &.align-middle {
        .card-inner .content-wrapper {
          justify-content: center;
        }
      }

      &.align-bottom {
        .card-inner .content-wrapper {
          justify-content: flex-end;
        }
      }
    }

    .obis-site-cardgallery-card.heightfactor-1,
    .obis-site-cardgallery-card.heightfactor-2 {
        min-height: 100px;
        max-height: 175px;
    }

    .obis-site-cardgallery-card.heightfactor-1 {
        .card-inner {
             .content-wrapper {
                 &>.neos-contentcollection {
                      h1 {
                          font-size: 3.5em;
                      }
                      h2 {
                          font-size: 3em;
                      }
                      h3 {
                          font-size: 2.5em;
                      }
                      h4 {
                          font-size: 2em;
                      }
                      h5 {
                          font-size: 1.5em;
                      }
                 }
            }
        }
    }

    .obis-site-cardgallery-card.heightfactor-2 {
        .card-inner {
             .content-wrapper {
                 &>.neos-contentcollection {
                      h1 {
                          font-size: 1.75em;
                      }
                      h2 {
                          font-size: 1.5em;
                      }
                      h3 {
                          font-size: 1.25em;
                      }
                      h4 {
                          font-size: 1em;
                      }
                      h5 {
                          font-size: 0.75em;
                      }
                 }
            }
        }
    }



  }
}
