.neos-nodetypes-text {
  &.color-dark {
    color: $medium-dark;
  }

  &.color-light {
    color: $light;
  }

  &.color-blue {
    color: $themecolor;
  }

  &.color-white {
    color: $white;
  }
}