.obis-site-headline {
  margin-bottom: 15px;
  word-break: break-word;
  overflow: hidden;

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1,
    h2,
    h3 {
      font-weight: bold;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 10px 0;
    }

    &>img {
      max-height: 50px;
      max-width: 50px;
      height: auto;
      width: auto;
      margin-right: 5px;
    }
  }

  .bar {
    height: 4px;
    width: 80px;
    background-color: $medium-dark;
  }

  &.align-left {
    text-align: left;

    .content {
      justify-content: flex-start;
    }
  }

  &.align-center {
    text-align: center;

    .content {
      justify-content: center;
    }

    .bar {
      margin: 0 auto;
    }
  }

  &.align-right {
    text-align: right;

    .content {
      justify-content: flex-end;
    }

    .bar {
      margin-left: auto;
    }
  }

  &.align-justify {
    text-align: justify;

    .bar {
      margin: 0 auto;
    }
  }

  &.color-light,
  &.color-white {
    color: $white;

    .bar {
      background-color: $white;
    }
  }

  &.color-dark {
    color: $medium-dark;

    .bar {
      background-color: $themecolor-dark;
    }
  }

  &.color-blue {
    color: $themecolor-dark;

    .bar {
      background-color: $medium-dark;
    }
  }
}
