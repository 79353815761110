section.content-section {
  padding: 35px 10px;

  &.color-light {
    color: $medium-dark;
    background-color: $light;
  }

  &.color-dark {
    color: $light;

    @include hsl-gray-background-gradient-radial;
  }

  &.color-blue {
    color: $light;

    @include hsl-blue-background-gradient-diagonal;
  }

  &.color-white {
    color: $medium-dark;
    background-color: $white;
  }
}