body.obis-site-homepage {
  section.header-slider {
    @include slide-height-mixin();
    width: 100%;

    .obis-site-slider {

      .slider-contents {

        .slick-list {

          .slick-track {

            .slick-slide {

              .obis-site-slider-slide {

                .slide-content-wrapper {

                  .slide-inner {

                    align-content: flex-end;

                    .neos-contentcollection {
                      z-index: 10;
                      margin-bottom: 30px;

                      @media (max-width: $screen-sm-max) {

                        h1 {
                          font-size: 5.0em;
                        }

                        h2 {
                          font-size: 4.5em;
                        }

                        h3 {
                          font-size: 3em;
                        }

                        h4 {
                          font-size: 2em;
                        }

                        h5 {
                          font-size: 1.6em;
                        }

                      }

                      @media (max-width: $screen-xs-max) {

                        h1 {
                          font-size: 3.0em;
                        }

                        h2 {
                          font-size: 3.5em;
                        }

                        h3 {
                          font-size: 2.5em;
                        }

                        h4 {
                          font-size: 2em;
                        }

                        h5 {
                          font-size: 1.6em;
                        }

                      }

                    }
                  }
                }
              }
            }
          }
        }
      }
    }


  }

  article.main-content {}
}
