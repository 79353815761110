a.obis-site-iconlink {
  font-size: inherit;
  color: inherit;
  margin: 0 2px;
  padding: 3px;

  &>span {
    display: inline-block;
    line-height: 130%;
    vertical-align: middle;

    .icon-box {
      font-size: 130%;
      line-height: 1.2em;
      margin-right: 5px;
      vertical-align: text-top;
    }

    .inner-text {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &.reversed>span {
    .icon-box {
      float: right;
      margin-right: unset;
      margin-left: 5px;
    }
  }

  &.color-white {
    color: $white;
  }

  &.color-dark {
    color: $dark;
  }

  &.color-blue {
    color: $themecolor-dark;
  }

  &.color-light {
    color: $themecolor;
  }

  &.align-left {
    display: block;
    text-align: left;
  }

  &.align-center {
    display: block;
    text-align: center;
  }

  &.align-right {
    display: block;
    text-align: right;
  }
}