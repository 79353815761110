div.obis-site-searchbox {
  position: relative;

  .search-input-wrapper {
    padding: 7px 0;
    width: 40px;

    transition: width 0.5s ease-in-out;

    input,
    button {
      border-radius: 0;
      border: none;
      opacity: 0.9;
      float: right;
    }

    input.search-input-field {
      background: $light;
      color: $dark;

      width: 0;
      padding: 0;

      transition: width 0.5s ease-in-out,
        padding 0.5s ease-in-out;
    }

    button.do-search {
      background: none;
      color: $themecolor;
      font-size: 14pt;
      padding: 4px 10px;
      min-width: 40px;
      outline: none !important;

      &:active {
        box-shadow: none;
      }
    }
  }

  .search-results-wrapper {
    max-width: 350px;

    ul.search-results {
      background: $light;
      color: $dark;
      list-style: none;
      max-height: 80vh;
      max-width: 80%;
      opacity: 0.9;
      overflow-y: auto;
      padding: 5px;
      position: absolute;
      width: 100%;

      li.search-result-item {
        .result-item-inner {
          margin-bottom: 3px;
          border-bottom: 1px solid $themecolor;
          font-size: 12pt;

          &>i {
            float: right;
            font-size: 10pt;
            vertical-align: middle;
          }
        }
      }

      .loader,
      .notice {
        text-align: center;
        margin: 10px 0;
      }

      &>.loader {
        font-size: 30pt;
        color: $themecolor;
      }

      &>.notice {
        font-size: 14pt;
        color: $dark;
      }
    }
  }

  &.active {
    .search-input-wrapper {
      width: 350px;

      input.search-input-field {
        width: 80%;
        padding: 6px 10px;
      }

      button.do-search {
        width: 20%;
      }
    }
  }
}