header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 999;

  .social-bar {
    color: $white;
    background: $themecolor-dark;
    font-size: 9pt;
    @media (min-width: $screen-sm-min) {
      font-size: 10pt;
    }
    padding: 5px 0;
    line-height: 13pt;

    .social-bar-inner {
      .social-content {
        @include fake-column(12);
        @media (min-width: $screen-sm-min) {
          @include fake-column(7);
        }

        .link-item {
          display: inline-block;
          margin: 0 8px;

          .icon {
            font-size: 13pt;
            vertical-align: middle;
          }
        }
      }

      .social-content-left {
        text-align: left;

        .link-item {
          .icon {
            margin-right: 10px;
          }

          &>span {
            line-height: 16pt;
            vertical-align: middle;
          }
        }
      }

      .social-content-right {
        text-align: right;
      }
    }
  }

  .header-inner-wrapper {
    background: $white;

    .header-inner {
      .header-inner-left-block {
        @include fake-column(8);

        @media (min-width: $screen-sm-min) {
          @include fake-column(4);
        }

        .home-link {
          display: block;
          max-width: 200px;
        }
      }

      .header-inner-right-block {
        @include fake-column(8);

        margin-top: 17px;

        @media (max-width: $screen-sm-max) {
          display: none;
        }

        &>* {
          display: block;
          float: right;
        }

        .obis-site-mainmenu {
          padding: 11px 0;
        }

        .obis-site-searchbox+.obis-site-mainmenu {
          transition: max-width 0.3s ease;
          max-width: 100%;
          overflow: hidden;
        }

        .obis-site-searchbox.active+.obis-site-mainmenu {
          max-width: 0%;
          max-height: 45px;
        }

        .obis-site-language-menu {
          margin-left: 5px;
        }
      }

      .header-inner-right-block-mobile {
        @include fake-column(4);

        margin-top: 17px;

        @media (min-width: $screen-sm-min) {
          display: none;
        }

        button.hamburger {
          position: absolute;
          right: 20px;
          z-index: 999;

          .hamburger-inner,
          .hamburger-inner::after,
          .hamburger-inner::before {
            background-color: $themecolor;
          }

          &.is-active {

            .hamburger-inner,
            .hamburger-inner::after,
            .hamburger-inner::before {
              background-color: $medium-dark;
            }
          }
        }
      }
    }
  }
}
