button.obis-site-button {
  background-color: $light;
  border-color: $light;
  border: 1px solid;
  color: $light;
  display: block;
  font-size: 15pt;
  font-weight: 900;
  font-weight: bold;
  min-width: 120px;
  outline: none !important;
  padding: 8px 25px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;

  &.color-light {
    background-color: $light;
    border-color: $light;
    color: $medium-dark;
  }

  &.color-dark {
    background-color: $medium-dark;
    border-color: $medium-dark;
    color: $white;
  }

  &.color-blue {
    background-color: $themecolor;
    border-color: $themecolor;
    color: $medium-dark;
  }

  &.color-white {
    background-color: $white;
    border-color: $white;
    color: $themecolor-dark;
  }

  &.align-center,
  &.align-justify {
    margin: 0 auto;
  }

  &.align-right {
    margin-left: auto;
  }

  i.fas {
    margin-left: 3px;
  }

  &.button-reverse {
    .button-inner {
      i.fas {
        margin-left: unset;
        margin-right: 3px;
      }

      &>span {
        float: right;
      }

      &::after {
        @include custom-clearfix;
      }
    }
  }
}