// Breakpoints
$screen-xs-min: 425px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;
$screen-xs-max: $screen-xs-min - 1 !default;
$screen-sm-max: $screen-sm-min - 1 !default;
$screen-md-max: $screen-md-min - 1 !default;
$screen-lg-max: $screen-lg-min - 1 !default;

// Colors
$lightblue: #59b8dd;
$blue: #0d377e;
$red: #ff0000;
$schulte-blue: #0081b1;

$themecolor: #444f4f;
$themecolor-dark: darken($themecolor, 10%);
$themecolor-light: lighten($themecolor, 10%);

// Shades
$dark: #262625;
$medium-dark: #575756;
$light: #e2e2e2;
$shadow: #a6a6a6;
$black: #000;
$white: #fff;
$overlay: rgba($black, 0.85);

// Set css variables
:root {
  --theme: $themecolor;
  --dark: $dark;
  --medium: $medium-dark;
  --light: $light;
  --shadow: $shadow;
}
