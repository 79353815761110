@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Muli/Muli-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}