.google-map-canvas {
  height: 400px;

  &.smart-google-map {
    &+.contact-box-wrapper {
      z-index: 10;

      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: 33%;
        left: 10%;
        right: 50%;
        max-width: 400px;
      }

      &>* {
        color: $white;
        opacity: 0.85;

        @include hsl-blue-background-gradient-diagonal;
      }

      .contact-box {
        height: 100%;
        padding: 10px;

        .informational-slider {
          .contact-information-slide {
            font-size: 12pt;

            .content-wrapper {
              .content {
                .flex-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: left;

                  i.glyphicons {
                    width: 30px;
                    font-size: inherit;
                    margin-right: 5px;
                  }
                }

                .company-name,
                .address,
                .phone,
                .fax,
                .mail {
                  margin-bottom: 10px;
                }

                .company-name {
                  .flex-wrapper {
                    font-size: 1.2em;
                    font-weight: 700;
                  }
                }

              }
            }
          }
        }
      }

      &+.contact-box-title {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;

        color: $medium-dark;
        background: linear-gradient(to bottom, rgba(212, 212, 212, 1) 0%, rgba(255, 255, 255, 0) 100%);

        font-size: 22pt;
        font-weight: 900;
        line-height: 22pt;
        text-align: center;
        text-transform: uppercase;
        padding-top: 20px;
        z-index: 11;

        &>p {
          margin-bottom: 0;
          @media (max-width: $screen-sm-min) {
            font-size: 18pt;
          }
        }

        &+.map-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 200px;
          z-index: 7;

          @media (min-width: $screen-sm-min) {
            bottom: 0;
          }
        }
      }
    }
  }

  &.hidden {
    display: none;

    &+.contact-box-wrapper {
      display: none;

      &+.contact-box-title {
        display: none;

        &+.map-overlay {
          display: none;
        }
      }
    }
  }
}