button.hamburger {
  outline: none !important;

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background-color: $medium-dark;
  }

  &.color-dark {

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $medium-dark;
    }
  }

  &.color-light {

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $light;
    }
  }

  &.color-blue {

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $themecolor;
    }
  }

  &.color-white {

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $white;
    }
  }
}