.neos-nodetypes-image {
  &>figure {
    &.neos-alignment-left img {
      margin-right: auto;
    }

    &.neos-alignment-center img {
      margin-left: auto;
      margin-right: auto;
    }

    &.neos-alignment-right img {
      margin-left: auto;
    }

    &>a {
      &>img {
        transform: scale(1.0);
        transition: transform 0.5s ease-in-out;
      }

      @media (min-width: $screen-sm-min) {
        &:hover>img {
          transform: scale(1.1);
        }
      }
    }
  }
}
